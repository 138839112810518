import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { setAuthenticated, userDetails, AuthToken } from '../../redux/actions';
import { LoginUser } from "../../services/ApiService";
import { toast } from "react-toastify";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({ username: "", password: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await LoginUser(data);
      if (response) {
        secureLocalStorage.setItem("isAuthenticated", true);
        secureLocalStorage.setItem("userDetails", response.data);
        secureLocalStorage.setItem("authtoken", response.token);
        dispatch(setAuthenticated(true));
        dispatch(userDetails(response.data));
        dispatch(AuthToken(response.token))
        navigate("/rules");
      } else {
        toast.error("Invalid credentials");
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error(error.response.data.message)
    }
  };

  return (
    <section className="hero_area__main form_sec formm-sec">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <form
              onSubmit={handleSubmit}
              className="p-4 p-md-6  rounded-3"
            >
            
            <div className="text-center">      <img src="./image/logonew.png" alt="" width={240} /> </div>
               
              <h2 className="mb-4 mt-4 text-center">Login</h2>
              <div className="mb-3">
                <label htmlFor="userId" className="form-label">
                  User ID
                </label>
                <input
                  type="text"
                  id="userId"
                  name="username"
                  value={data.username}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="Password"
                  value={data.Password}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="d-grid bt-bg">
                <button
                  type="submit"
                  className="btn btn-secondary btn-lg w-100"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
