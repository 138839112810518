import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './homeStyle.css'; // Include this for styling
import { FiPlayCircle } from 'react-icons/fi';

// Home Component
function Home() {
  return (
    <>
      <div>
        <section className="desbord">
          <div className="container">
            <div className="row">
              {[
                { to: '/in-Play', imgSrc: '/images/iconsmain/cricket.gif', text: 'In Play' },
                { to: '/profile', text: 'Profile', imgSrc: '/images/iconsmain/male-user-profile.gif' },
                { to: '/statement', text: 'Statement', imgSrc: '/images/iconsmain/receipt.gif' },
                { to: '/completed-games', text: 'Completed Games', imgSrc: '/images/iconsmain/game-developer.gif' },
                { to: '/my-ledger', text: 'My Ledger', imgSrc: '/images/iconsmain/income-balance.gif' },
                { to: '/rules', text: 'Rules', imgSrc: '/images/iconsmain/rules-and.gif' },
                { to: '/change-password', text: 'Change Password', imgSrc: '/images/iconsmain/password.gif' },
                { to: '/', text: 'Help & Support', imgSrc: '/images/iconsmain/help.gif' }
              ].map((item, index) => (
                <div key={index} className="col-6 col-sm-3 col-md-3 col-lg-3">
                  <Link to={item.to}>
                    <div className="sec_desbord_card">
                      <div className="sec_desbord_img">
                        <img src={item.imgSrc} alt={item.text} />
                      </div>
                      <div className="sec_desbord_tx">
                        <p>{item.text}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

// VideoSection Component
const VideoSection = ({ thumbnail, link, overlayText }) => {
  const handleVideoClick = () => {
    // Open link in the same window/tab
    window.location.href = link;
  };

  return (
    <div className="col-6 col-md-3 col-lg-3 mb-4 allGames">
      <div className="video-container" onClick={handleVideoClick}>
        <img src={thumbnail} alt="Video Thumbnail" className="video-thumbnail" />
        {overlayText && (
          <div className="overlay-text" style={{ fontSize: '10px' }}>
            {overlayText}
          </div>
        )}
        <div className="play-icon">
          <FiPlayCircle size={50} />
        </div>
      </div>
    </div>
  );
};

// App Component
function App() {
  // const videos = [
  //   { thumbnail: '/image/anderbahr.jpg', link: '/andarbahar' },
  //   { thumbnail: '/image/amer.jpg', link: '/aaa' },
  //   { thumbnail: '/image/7b.jpg', link: '/lucky7b' },
  //   { thumbnail: '/image/3pati.jpg', link: '/teenpatti-t20' },
  //   { thumbnail: '/image/tg20.jpg', link: '/dt20' },
  //   { thumbnail: '/image/tg2.jpg', link: '/dt202' },
  //   { thumbnail: '/image/ander_mn.jpg', link: '/anderbahar2' },
  //   // { thumbnail: '/image/ludo.jpg', link: '/ludo' },
  //   // { thumbnail: '/image/Carrom.jpg', link: '/carrom' },
  //   // { thumbnail: '/image/Roulette.jpg', link: '/roulette' },
  //   // { thumbnail: '/image/kalyan.jpg', link: '/kuber', overlayText: '04:00 PM -- 06:00 PM' },
  //   // { thumbnail: '/image/cricket.jpg', link: '/in-Play'},
    
  // ];

  const videos = [
    { thumbnail: '/image/anderbahr.jpg', link: '#' },
    { thumbnail: '/image/amer.jpg', link: '#' },
    { thumbnail: '/image/7b.jpg', link: '#' },
    { thumbnail: '/image/3pati.jpg', link: '#' },
    { thumbnail: '/image/tg20.jpg', link: '#' },
    { thumbnail: '/image/tg2.jpg', link: '#' },
    { thumbnail: '/image/ander_mn.jpg', link: '#' },
    // { thumbnail: '/image/ludo.jpg', link: '/ludo' },
    // { thumbnail: '/image/Carrom.jpg', link: '/carrom' },
    // { thumbnail: '/image/Roulette.jpg', link: '/roulette' },
    // { thumbnail: '/image/kalyan.jpg', link: '/kuber', overlayText: '04:00 PM -- 06:00 PM' },
    // { thumbnail: '/image/cricket.jpg', link: '/in-Play'},
    
  ];

  return (
    <>
      <Home />
      <div className="container mt-5">
        <div className="row">
          {videos.map((video, index) => (
            <VideoSection
              key={index}
              thumbnail={video.thumbnail}
              link={video.link}
              overlayText={video.overlayText}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default App;

