import io from 'socket.io-client';

const SOCKET_URL = 'https://api.bro999.in';
// const SOCKET_URL = 'http://localhost:5000';

let socket = null;

export const initializeSocket = () => {
  if (socket && socket.connected) {
    console.log('Socket already connected');
    return socket;
  }

  socket = io(SOCKET_URL, {
    reconnectionAttempts: 10,
    reconnectionDelay: 5000,
  });

  socket.on('connect', () => {
    console.log('Connected to Socket.io server');
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from Socket.io server');
  });

  socket.on('reconnect', (attempt) => {
    console.log(`Reconnected to Socket.io server after ${attempt} attempts`);
  });

  socket.on('reconnect_attempt', (attempt) => {
    console.log(`Reconnection attempt ${attempt}`);
  });

  socket.on('reconnect_error', (error) => {
    console.error('Reconnection error:', error);
  });

  return socket;
};

export const joinMatch = (gameid) => {
  if (socket && socket.connected) {
    socket.emit('joinMatch', gameid);
    console.log(`Joined match room: match_${gameid}`);
  } else {
    socket.emit('joinMatch', gameid);
    console.log(`Joined match room: match_${gameid}`);
  }
};

export const joinCasinoGame = (gameId) => {
  if (socket && socket.connected) {
    socket.emit('joinCasinoGame', gameId);
    console.log(`Joined 1 casino room: casino ${gameId}`);
  } else {
    socket.emit('joinCasinoGame', gameId);
    console.log(`Joined 2 casino room: casino ${gameId}`);
  }

  if (!socket || !socket.connected) {
    initializeSocket();
  }
  socket.emit('joinCasinoGame', gameId);
  console.log(`Joined 3 casino room: casino ${gameId}`);

};

export const leaveCasinoGame = (gameId) => {
  if (socket && socket.connected) {
    socket.emit('leaveCasinoGame', gameId);
    console.log(`Left casino room: casino_${gameId}`);
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};

export default socket;
