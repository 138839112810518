// axiosInstance.js
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';

// const baseurl = 'http://localhost:5000/api/v1/user';
const baseurl = 'https://api.bro999.in/api/v1/user'
//


const axiosInstance = axios.create({
    baseURL: baseurl,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Optional: You can add an interceptor to update the token dynamically
axiosInstance.interceptors.request.use(config => {
    const token = secureLocalStorage.getItem('authtoken'); // Retrieve token fresh each time
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Add response interceptor
axiosInstance.interceptors.response.use(
    response => response, // If the response is fine, return it
    error => {
        const { response } = error;
        if (response && response.status === 401) {
            // Token expired or unauthorized
            secureLocalStorage.removeItem('isAuthenticated');
            secureLocalStorage.removeItem('userDetails');
            secureLocalStorage.removeItem("authtoken");
            window.location.href = '/login'; // Redirect to login page
            toast.error('Token expire')
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
