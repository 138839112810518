import secureLocalStorage from "react-secure-storage";
import { SET_AUTHENTICATED, HEADER_PROFILE, AUTH_TOKEN } from './actionTypes';
import { GetHeaderProfile } from "../services/ApiService";
export const LOGOUT = "LOGOUT";
export const USER_DETAILS = 'USER_DETAILS';


export const setAuthenticated = (isAuthenticated) => ({
  type: SET_AUTHENTICATED,
  payload: isAuthenticated,
});

export const userDetails = (user) => ({
  type: USER_DETAILS,
  payload: user,
});
export const AuthToken = (user) => ({
  type: AUTH_TOKEN,
  payload: user,
});

export const logout = () => {
  secureLocalStorage.removeItem('isAuthenticated');
  secureLocalStorage.removeItem('userDetails');
  secureLocalStorage.removeItem('authtoken');

  return {
    type: LOGOUT,
  };
};

// export const fetchUserHeader = () => async (dispatch) => {
//   try {
//     const id = secureLocalStorage.getItem('userDetails');
//     if (id) {
//       const userDetails = await GetHeaderProfile(id); // Fetch user details from API
//       dispatch({
//         type: HEADER_PROFILE,
//         payload: userDetails.data
//       });
//     }
//   } catch (error) {
//     console.error("Error fetching user details:", error);
//   }
// };
export const fetchUserHeader = () => async (dispatch) => {
  try {
    const id = secureLocalStorage.getItem('userDetails');
    if (id) {
      const userDetails = await GetHeaderProfile(id); // Fetch user details from API
      dispatch({
        type: HEADER_PROFILE,
        payload: userDetails.data
      });
    }
  } catch (error) {
    console.error("Error fetching user details:", error);
  }
};