import axiosInstance from './axiosInstance';

export const GetMatch = async () => {
    try {
        const response = await axiosInstance.get(`/match`);
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
}


export const GetMatchDetails = async (gameid) => {
    try {
        const response = await axiosInstance.get(`/GetMatchListDate/${gameid}`);
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
}

export const AddBet = async (data) => {
    try {
        const response = await axiosInstance.post(`/AddBets`, data, );
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};


export const AddCasinoBet = async (data) => {
    try {
        const response = await axiosInstance.post(`/AddCasinoBets`, data, );
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};


export const getBets = async (id,gameid) => {
    try {
        const response = await axiosInstance.get(`/getBets/${id}?gameid=${gameid}`, );
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};

export const getCasinoBets = async (id,gameId) => {
    try {
        const response = await axiosInstance.get(`/CasinoBets/${id}?gtype=${gameId}`, );
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};

export const getrouletteActiveBets = async (id) => {
    try {
        const response = await axiosInstance.get(`/roulette/${id}`, );
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};

export const GetCompleteBets = async (id,gameid) => {
    try {
        const response = await axiosInstance.get(`/getcompleteBets/${id}?gameid=${gameid}`, );
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};

export const AddBookMakerBets = async (data) => {
    try {
        const response = await axiosInstance.post(`/AddBookMakerBets`, data, );
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};
export const getBookMkerBets = async (id,gameid) => {
    try {
        const response = await axiosInstance.get(`/getBookMkerBets/${id}?gameid=${gameid}`, );
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};


export const getBookMakerAmount = async (id,gameid) => {
    try {
        const response = await axiosInstance.get(`/BookMakerBetsAmount/${id}?gameid=${gameid}`, );
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};
export const getBookMkercompleteBets = async (id,gameid) => {
    try {
        const response = await axiosInstance.get(`/getBookMkercompleteBets/${id}?gameid=${gameid}`);
        return response.data; 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};





export const GetHeaderProfile = async (id) => {
    try {
        const response = await axiosInstance.get(`/userCoins/${id}`);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

export const GetProfileInfo = async (id) => {
    try {
        const response = await axiosInstance.get(`/userInfo/${id}`);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

export const GetStatement = async (id) => {
    try {
        const response = await axiosInstance.get(`/getStatementById/${id}`);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

export const GetCompleteGames = async (id) => {
    try {
        const response = await axiosInstance.get(`/completed-games/${id}`);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}
export const GetMatchList = async () => {
    try {
        const response = await axiosInstance.get(`/MatchList`);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

export const GetLedgerdata = async (id) => {
    try {
        const response = await axiosInstance.get(`/getLedger/${id}`);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

export const LoginUser = async (data) => {
    try {
        const response = await axiosInstance.post(`/login`,data);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

export const userResetPassword = async (data) => {
    try {
        const response = await axiosInstance.put(`/ResetPassword`,data);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}


export const betsnumber = async () => {
    try {
        const response = await axiosInstance.get(`/RolletgetNumber/777/bharatjagoar`);
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}

export const userLostbet = async () => {
    try {
        const response = await axiosInstance.post(`/RolletgetNumber/777/bharatjagoar`,{
            username: "bharatjagoar",
            result: 0
        });
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}


export const userWinbet = async () => {
    try {
        const response = await axiosInstance.post(`/RolletgetNumber/777/bharatjagoar`,{
            username: "bharatjagoar",
            result: 1
        });
        return response.data; // Return response data if needed
    } catch (error) {
        console.error(error);
        throw error; // Optionally rethrow the error if you want to handle it elsewhere
    }
}


