// import React from 'react';
// import '../inPlay/in-PlayStyle.css';
// import { useState } from 'react';
// import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
// import '../change-password/change-passwordStyle.css'

// const ChangePassword = () => {
//     const [oldPassword, setOldPassword] = useState('');
//     const [newPassword, setNewPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [error, setError] = useState('');

//     const handleSubmit = (event) => {
//         event.preventDefault();

//         // Basic validation
//         if (newPassword !== confirmPassword) {
//             setError("New password and confirm password don't match.");
//             return;
//         }

//         // TODO: Add password change logic here
//         console.log("Old Password:", oldPassword);
//         console.log("New Password:", newPassword);
//         console.log("Confirm Password:", confirmPassword);

//         // Clear form fields
//         setOldPassword('');
//         setNewPassword('');
//         setConfirmPassword('');
//         setError('');
//     };

//     return (
//         <>
//             <Container className='changeForm'>
//                 <Row className="justify-content-md-center">
//                     <Col md={4}>
//                     <div className='fomr_pass'>
//                     <h2>Change Password</h2>
//                         <Form onSubmit={handleSubmit}>
//                             {error && <Alert variant="danger">{error}</Alert>}
//                             <Form.Group controlId="oldPassword">
//                                 <Form.Label>Old Password</Form.Label>
//                                 <Form.Control
//                                     type="password"
//                                     value={oldPassword}
//                                     onChange={(e) => setOldPassword(e.target.value)}
//                                     required
//                                 />
//                             </Form.Group>

//                             <Form.Group controlId="newPassword">
//                                 <Form.Label>New Password</Form.Label>
//                                 <Form.Control
//                                     type="password"
//                                     value={newPassword}
//                                     onChange={(e) => setNewPassword(e.target.value)}
//                                     required
//                                 />
//                             </Form.Group>

//                             <Form.Group controlId="confirmPassword">
//                                 <Form.Label>Confirm Password</Form.Label>
//                                 <Form.Control
//                                     type="password"
//                                     value={confirmPassword}
//                                     onChange={(e) => setConfirmPassword(e.target.value)}
//                                     required
//                                 />
//                             </Form.Group>

//                             <Button variant="primary" type="submit" className='mt-2' style={{ backgroundColor: '#ff1616', borderColor: '#ff1616' }}>
//                                 Done
//                             </Button>
//                         </Form>
//                     </div>

//                     </Col>
//                 </Row>
//             </Container>
//         </>
//     );
// };

// export default ChangePassword;

import React from "react";
import "../inPlay/in-PlayStyle.css";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../change-password/change-passwordStyle.css";
import { userResetPassword } from "../../services/ApiService";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const validationSchema = Yup.object({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .min(6, "Password must be at least 6 characters long"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords don't match"), // Validation for matching passwords
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log("Old Password:", values.oldPassword);
      console.log("New Password:", values.newPassword);
      console.log("Confirm Password:", values.confirmPassword);

      try {
        const response = await userResetPassword({
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        });

        if (response) {
          toast.success("Password Succesfully Updated");
        }
      } catch (error) {
        toast.error(error.response.data.message)
        console.warn(error.response.data.message)
      }

      // Clear form fields
      formik.resetForm();
    },
  });

  return (
    <Container className="changeForm">
      <Row className="justify-content-md-center">
        <Col md={4}>
          <div className="fomr_pass">
            <h2>Change Password</h2>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group controlId="oldPassword">
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  type="password"
                  name="oldPassword"
                  value={formik.values.oldPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.oldPassword && !!formik.errors.oldPassword
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.oldPassword}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="newPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  name="newPassword"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.newPassword && !!formik.errors.newPassword
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.newPassword}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.confirmPassword &&
                    !!formik.errors.confirmPassword
                  }
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                className="mt-2"
                style={{ backgroundColor: "#ff1616", borderColor: "#ff1616" }}
              >
                Done
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ChangePassword;
