import React, { useEffect, useState } from "react";
import { GetCompleteGames, GetMatchList } from "../../services/ApiService";
import { useSelector } from "react-redux";
import BackToMenu from "../../Components/BackToMenu";
import { Link } from "react-router-dom";
import { formatDate, formatDateByTime } from "../../Components/FormateDate";


const YourComponent = ({
  gameid,
  openDate,
  TeamA,
  team1Img,
  TeamB,
  team2Img,
  winner,
  loss,
}) => {
  return (
    <>
      <div className="col-sm-12 col-md-4 col-lg-4 ">
        <Link to={`/Play-Match/${gameid}`}>
          <div className="hero_area__topslider-card swiper-slide p-2 p-md-6">
            <div className="hero_area__topslider-cardtop d-flex align-items-center justify-content-between mb-2 mb-md-6">
              <div className="d-flex align-items-center gap-2 ">
                {/* <i className="ti ti-ball-football n5-color"></i> */}
                <span className="fs-seven n5-color cpoint date_gg">{formatDate(openDate)}</span>
              </div>
              <span className="fs-seven n5-color cpoint date_gg">{formatDateByTime(openDate)}</span>
            </div>
            <div className="hero_area__topslider-cardbody text-center mb-2 mb-md-6">
              <div className="hero_area__topslider-flag text-center">
                {/* <div className="hero_area__topslider-flagbox mb-2">
                  <img src={team1Img} alt="icon" />
                </div> */}
                <h6 className="cpoint">{TeamA}</h6>
              </div>
              <div className="hero_area__topslider-vs">
                <span className="fw-bold n5-color">VS</span>
              </div>
              <div className="hero_area__topslider-flag text-center flex-column ">
                {/* <div className="hero_area__topslider-flagbox mb-2">
                  <img src={team2Img} alt="icon" />
                </div> */}
                <h6 className="cpoint">{TeamB}</h6>
              </div>
            </div>
            {/* <div className="hero_area__topslider-cardfooter d-flex align-items-center justify-content-between gap-4">
              <div className="hero_area__topslider-cfitem d-flex align-items-center gap-4 py-2 justify-content-center w-100 p2-bg cpoint">
                <span className="fs-eight n5-color">Won By : </span>
                <span className="fw-bold fs-eight">{winner}</span>
              </div>
              <div className="hero_area__topslider-cfitem d-flex align-items-center gap-4 py-2 justify-content-center w-100 p2-bg cpoint">
                <span className="fs-eight n5-color">Loss : </span>
                <span className="fw-bold fs-eight">{loss}</span>
              </div>
            </div> */}
          </div>
        </Link>
      </div>
    </>
  );
};

const HeroSection = () => {
  const [data, setdata] = useState([]);
  const { user } = useSelector((state) => state.auth);

  


  useEffect(() => {
    (async () => {
      const data = await GetMatchList(user);
      if (data) {
        setdata(data.data);
      }
    })();
  }, []);

  return (
    <>
      <section className="hero_area__main">
        <div className="container">
          <div className="row">
            {data.map((match, index) => (
              <YourComponent key={index} {...match} />
            ))}
          </div>
          <BackToMenu />
        </div>
      </section>
    </>
  );
};

export default HeroSection;
